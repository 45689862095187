
import { Pagination as BootstrapPagination } from 'react-bootstrap';

const Pagination = ({ totalItems, itemsPerPage, currentPage, setCurrentPage }) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage); // Calcul du nombre total de pages

    // Générer un tableau des numéros de page
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
    }

    return (
        <div className="d-flex justify-content-center mt-3">
            <BootstrapPagination size="sm">
                {/* Bouton précédent */}
                <BootstrapPagination.Prev
                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                >
                    Précédent
                </BootstrapPagination.Prev>

                {/* Boutons de numéros de page */}
                {pages.map((page) => (
                    <BootstrapPagination.Item
                        key={page}
                        active={currentPage === page}
                        onClick={() => setCurrentPage(page)}
                    >
                        {page}
                    </BootstrapPagination.Item>
                ))}

                {/* Bouton suivant */}
                <BootstrapPagination.Next
                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                    disabled={currentPage === totalPages}
                >
                    Suivant
                </BootstrapPagination.Next>
            </BootstrapPagination>
        </div>
    );
};

export default Pagination;