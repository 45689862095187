import React, { useEffect, useState } from 'react';
import "../css/profil.css";
import Langue from '../sousComposants/langue';
import Retour2 from '../sousComposants/retour2';

function Profil({ setcurrentRoute }) {
    // État pour stocker les informations de l'utilisateur
    const [user, setUser] = useState({
        name: '',
        email: '',
        age: '',
        address: '',
        profilePicture: '',
    });

    // Simuler un appel d'API pour récupérer les informations de l'utilisateur
    useEffect(() => {
        const fetchUserData = () => {
            // Simuler les données de l'utilisateur
            const userData = {
                name: 'Daniel Phanuel Ndong',
                email: 'Daniel.doe@example.com',
                Service: 'Informatique',
                Fonction: 'Igenieur informaticien',
                address: '123 Main St, New York, NY 10001',
                'Nombre de demande Reçu': 100,
                'Nombre de demande Validées': 18,
                'Nombre de demande Refusées': 30,
                'Nombre de Demande transféré': 12,

                profilePicture: 'https://randomuser.me/api/portraits/men/75.jpg',
            };

            // Mettre à jour l'état avec les données de l'utilisateur
            setUser(userData);
        };

        fetchUserData();
    }, []);

    return (
        <div style={{ marginTop: "100px" }}>

            <div className="container d-flex justify-content-between align-items-center">
                <Retour2 setcurrentRoute={setcurrentRoute} />
                <Langue />
            </div>
            <div className="profile-container">
                <div className="profile-card d-flex flex-column justify-content-centre align-items-center align-content-center">
                    <div className='d-flex flex-column justify-content-start align-items-start align-content-start'>
                        <h1 className='fw-bolder mb-3'>{user.name}</h1>
                        <p className='fs-6'><strong className='stong'>Email:</strong> {user.email}</p>
                        <p className='fs-6'><strong className='stong'>Service:</strong> {user.Service}</p>
                        <p><strong className='stong'>Fonction:</strong> {user.Fonction}</p>
                        <p><strong className='stong'>Nombre de demandes reçues:</strong> {user['Nombre de demande Reçu']}</p>
                        <p><strong className='stong'>Nombre de demandes validées:</strong> {user['Nombre de demande Validées']}</p>
                        <p><strong className='stong'>Nombre de demandes refusées:</strong> {user['Nombre de demande Refusées']}</p>
                        <p><strong className='stong'>Nombre de demandes transférées:</strong> {user['Nombre de Demande transféré']}</p>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profil
