import { faFileLines, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Langue from '../sousComposants/langue';
import Pagination from '../sousComposants/pagination';
import Retour from '../sousComposants/retour';

function Demandesrefus({ setcurrentRoute }) {
    const items = [];

    for (let i = 0; i < 100; i++) {
        items.push(
            <Link to="/documentsrefus" className='text-decoration-none' onClick={() => setcurrentRoute('documentsrefus')}>
                <div className='line-file border border-1 rounded rounded-3 my-1 mx-3 p-2 h-25 w-95 d-flex justify-content-between'>
                    <div className='s1' style={{ color: "#0c235f" }}>Acte de naissance : DEMANDE4958574348B</div>
                    <div className='d-flex justify-content-around'>
                        <div className='mx-2 s2'>{new Date().toLocaleDateString()}{new Date().toLocaleTimeString()}</div>
                        <div className='s2' style={{ color: "#0c235f" }}><FontAwesomeIcon icon={faFileLines} /></div>
                    </div>
                </div>
            </Link>
        );
    }
    // Nombre d'éléments par page
    const itemsPerPage = 10;

    // États pour gérer la page actuelle
    const [currentPage, setCurrentPage] = useState(1);

    // Calculer les éléments à afficher sur la page courante
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);
    return (
        <div className='mt-5 pt-5'>
            <div className="container d-flex justify-content-between align-items-center">
                <Retour setcurrentRoute={setcurrentRoute} />
                <Langue />
            </div>
            <div className='col-lg-6 mx-auto mt-4 d-flex align-content-center justify-content-around'>
                <div className='px-3 rounded-start rounded-start-2 d-flex align-items-center text-light' style={{ backgroundColor: '#8aa3e1' }}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                </div>
                <input
                    className="form-control"
                    type="text"
                    name="phoneNumber"
                    placeholder="Rechercher un document"
                ></input>
            </div>
            <div className='col-9 mx-auto mt-5 border border-2 rounded rounded-3 pt-3 mb-5 d-flex flex-column align-items-stretch divhaute'>

                {currentItems.map((item, index) => (
                    <div key={index}>{item}</div>
                ))}
            </div>
            <div className='mb-5 d-flex flex-column justify-content-center align-items-center align-content-center'>

                {/* Composant de pagination */}
                <Pagination
                    totalItems={items.length}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            </div>
        </div>
    )
}

export default Demandesrefus
