import React from 'react'
import { Link } from 'react-router-dom'
import Langue from '../sousComposants/langue'
import Retour from '../sousComposants/retour'

function NumLegalisation({ setcurrentRoute }) {
    return (
        <div className='mt-5 pt-5 ms-lg-5 d-flex flex-column'>
            <div className="container m-auto row">
                <Retour setcurrentRoute={setcurrentRoute} />
                <Langue />
            </div>
            <div className='align-self-center d-flex flex-column align-items-center'>
                <h2 className='fw-bold fs-5 mb-5 mt-3 mx-3 text-center' style={{ color: "#0c235f" }}>Veuillez renseigner le Numéro de légalisation</h2>
                <input
                    className="form-control"
                    type="text"
                    name="phoneNumber"
                    placeholder="Entrer le numéro de légalisation"
                />
                <Link onClick={() => {
                    setcurrentRoute("document")
                }}
                    className="text-decoration-none"
                    to={'/document'}>
                    <button className='btn btn-outline-light fw-bold mt-4 px-5' style={{ backgroundColor: "#496fcf" }}>Valider</button>
                </Link>
            </div>
        </div>
    )
}

export default NumLegalisation