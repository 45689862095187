import { faEnvelope, faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Langue from '../sousComposants/langue'
import Retour from '../sousComposants/retour'

function Contact({ setcurrentRoute }) {
    return (
        <div className='mt-5 pt-5 ms-lg-5 d-flex flex-column'>
            <div className="container d-flex justify-content-between align-items-center">
                <Retour setcurrentRoute={setcurrentRoute} />
                <Langue />
            </div>

            <div className="mx-auto " data-wow-duration="1s" data-wow-delay=".75s">
                <h2 className='fw-bold fs-3 mb-2 mt-1 mx-3 mx-lg-0 text-center' style={{ color: "#0c235f" }}>Nos Coodonnées</h2>
                <div className="tiret mb-3"></div>
                <div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <FontAwesomeIcon icon={faMapMarkerAlt} /> 123, Rue de la République, 75000 Yaoundé
                        </li>
                        <li className="list-group-item">
                            <FontAwesomeIcon icon={faPhoneAlt} /> +237 692 494 794
                        </li>
                        <li className="list-group-item">
                            <FontAwesomeIcon icon={faEnvelope} />contact@AngaraGroup.com
                        </li>
                    </ul>
                </div>
            </div>

            <div className='mt-2 mb-4'>
                <h2 className='fw-bold fs-3 mb-2 mt-3 mx-3 mx-lg-0 text-center' style={{ color: "#0c235f" }}>Contactez nous</h2>
            </div>

            <div className="container col-lg-6 mb-5 mt-2 mx-auto" data-wow-duration="1s" data-wow-delay=".25s">
                <p className='fw-bold'>Pour nous contacter, merci de bien vouloir remplir le formulaire suivant</p>
                <p className='fw-bold'>Nos équipes s'engage à vous répondre sous 24 heures </p>
                <form action="" method="post" className="row" data-wow-duration="1s" data-wow-delay=".5s">
                    <div className="col-lg-6">
                        <label for="nom" className="form-label">Nom * :</label>
                        <div className="">
                            <input type="text" className="form-control  " id="nom" name="nom" required />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <label for="prenom" className="col-form-label">Prénom * :</label>
                        <div className="">
                            <input type="text" className="form-control  " id="prenom" name="prenom" required />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <label for="tel" className="col-form-label">Téléphone * :</label>
                        <div className="">
                            <input type="tel" className="form-control " id="tel" name="tel" required />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <label for="email" className="col-form-label">Email * :</label>
                        <div className="">
                            <input type="email" className="form-control " id="email" name="email" required />
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <label for="code" className="col-form-label">Objet * :</label>
                        <div className="">
                            <input type="text" className="form-control  " id="code" name="code" required />
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <label for="message" className="col-form-label">Votre méssage * :</label>
                        <div className="">
                            <textarea className="form-control" name="message" id=""></textarea>
                        </div>
                    </div>
                    <div className="col-lg-12 pt-3 mb-3 d-flex justify-content-center">
                        <button className="btn btn-primary" type="button" style={{ backgroundColor: "#496fcf", color: "white" }}>Envoyer</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Contact