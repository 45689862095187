import React from 'react'
import { Link } from 'react-router-dom'
import '../css/login.css'
import logo from '../images/logo.jpg'
import Langue from '../sousComposants/langue'

function Login({ setcurrentRoute }) {
    return (
        <div className='mt-5 pt-5 d-flex flex-column'>
            <div className="container m-auto row">
                <div className="col-2">
                    <img className="img" src={logo} alt='Logo' />
                </div>
                <div className="col-8 mt-2 d-flex flex-column justify-content-center align-items-center align-content-center">
                    <h1 className="display-6">Bienvenue</h1>
                    <p className="p1">Sur</p>
                </div>
                <div className='col-2'>
                    <Langue />
                </div>
            </div>
            <div>
                <h1 className="text-center fw-bold ANGARA">ANGARA AUTHENTIC</h1>
                <p className="text-center p1">Votre portail de demande de copie certifier conforme de document officiel </p>
            </div>
            <form className='container mt-2 '>

                <div className='col-lg-6 col-10 mx-auto d-flex flex-column align-items-center'>
                    <label className="form-label">Login :</label>
                    <input
                        className="form-control"
                        type="text"
                        name="cardNumber"
                        placeholder="Renseignez le login"
                    />
                </div>
                <div className='col-lg-6 col-10 mx-auto mt-2 d-flex flex-column align-items-center'>
                    <label className="form-label">Mot de passe :</label>
                    <input
                        className="form-control"
                        type="text"
                        name="cardNumber"
                        placeholder="Entrez le mot de passe"
                    />
                </div>
            </form>
            <div className='align-self-center '>
                <Link onClick={() => {
                    setcurrentRoute("demandesrecu")
                }}
                    className=" text-decoration-none"
                    to={'/demandesrecu'}>
                    <button className='btn btn-outline-light fw-bold mt-4 px-5' style={{ backgroundColor: "#496fcf" }}>Valider</button>
                </Link>

            </div>
        </div>
    )
}

export default Login