import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../css/navbarre.css';

function Navbarre({ ...props }) {
    return (
        <Navbar expand="lg" className='navbar fixed-top navbar-expand-lg  d-flex justify-content-end m-1 p-3 '>
            <Container className="nav na-pills pe-2">

                <Navbar.Toggle className="bg-light mb-1" aria-controls="basic-navbar-nav" />

                <Navbar.Collapse id="basic-navbar-nav">
                    <Navbar.Brand className="d-flex flex-column justify-content-center align-items-center" aria-controls="basic-navbar-nav">
                        <div className=" d-flex justify-content-around align-content-center align-items-center">

                            <Link onClick={() => {
                                props.setcurrentRoute("profil")
                            }}
                                className="text-decoration-none"
                                to={'/profil'}>
                                <div className="user me-2">
                                    <FontAwesomeIcon icon={faUser} />
                                </div>
                            </Link>
                            <div>
                                <div className="text-light fs-6 align-self-start">Ndong Medjo Daniel (MINJUSTICE )</div>
                                <div className="btn btn-outline-light btn-sm">Se déconnecter</div>
                            </div>
                        </div>
                    </Navbar.Brand>
                    <Nav className="ms-auto">
                        <Link onClick={() => {
                            props.setcurrentRoute('contact')
                        }}
                            className={
                                props.currentRoute === "contact"
                                    ? "btn bout btn-light ms-2 mt-2 fw-bolder"
                                    : "btn bout btn-outline-light mt-2 ms-2 fw-bolder"
                            }

                            to={'/contact'}>Contact
                        </Link>
                        <Link onClick={() => {
                            props.setcurrentRoute("demandesrecu")
                        }}
                            className={
                                props.currentRoute === "demandesrecu"
                                    ? "btn bout btn-light ms-2 mt-2 fw-bolder"
                                    : "btn bout btn-outline-light mt-2 ms-2 fw-bolder"
                            }
                            to={'/demandesrecu'}>
                            Demandes Reçus
                        </Link>
                        <Link onClick={() => {
                            props.setcurrentRoute("demandesvalide")
                        }}
                            className={
                                props.currentRoute === "demandesvalide"
                                    ? "btn bout btn-light ms-2 mt-2 fw-bolder"
                                    : "btn bout btn-outline-light mt-2 ms-2 fw-bolder"
                            }
                            to={'/demandesvalide'}>
                            Demandes Signées
                        </Link>
                        <Link onClick={() => {
                            props.setcurrentRoute("demandesrefus")
                        }}
                            className={
                                props.currentRoute === "demandesrefus"
                                    ? "btn bout btn-light ms-2 mt-2 fw-bolder"
                                    : "btn bout btn-outline-light mt-2 ms-2 fw-bolder"
                            }
                            to={'/demandesrefus'}>
                            Demandes Refusées
                        </Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Navbarre