import { faPenToSquare, faRectangleXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import '../css/document.css'
import logo from '../images/BUNEC.png'
import qrcode from '../images/qr-code.png'
import Langue from './langue'
import Retour2 from './retour2'

function DocumentsRecu({ setcurrentRoute }) {
    return (
        <div style={{ marginTop: "100px" }}>
            <div className="container d-flex justify-content-between align-items-center">
                <Retour2 setcurrentRoute={setcurrentRoute} />
                <Langue />
            </div>
            <div className='col-9 mt-5 mx-auto border border-2 pt-5 mb-5 d-flex flex-column align-items-stretch divhaute'>
                <div className='d-flex justify-content-between mx-2'>
                    <div className='d-flex flex-column align-items-center mb-3'>
                        <h1 className='fw-bolder fs-5 text-center s1'>REPUBLIQUE DE CAMEROUN</h1>
                        <h6 className='text-center s2'>Paix - Travail - Patrie</h6>
                        <div className='bar1'></div>
                        <h1 className='fw-medium fs-6 text-center mt-1 s1'>TRIBUNAL DE PREMIERE INSTENCE DE
                        </h1>
                        <input type='text' className='monInput form-control mb-1' />
                        <div className='bar11'></div>
                    </div>
                    <div className='mb-3'>
                        <img className="img img-fluid" id='bunec' src={logo} alt='Logo' />
                    </div>
                    <div className='d-flex flex-column align-items-center mb-3'>
                        <h1 className='fw-bolder fs-5 text-center s1'>REPUBLIC OF CAMEROON</h1>
                        <h6 className='text-center s2'>Peace - Work - Fatherland</h6>
                        <div className='bar1'></div>
                        <h1 className='fw-medium fs-6 text-center mt-1 s1'>COURT OF FIRST INSTANCE OF</h1>
                        <input type='text' className='monInput form-control mb-1' />
                        <div className='bar11'></div>
                    </div>
                </div>
                <div className=' mt-5 row'>
                    <div className='col-4'>
                        <p className='fw-bold text-center align-self-center s2'>LAGALISATION4958574348B</p>
                    </div>
                    <div className='col-2'></div>
                    <div className='col-4 h-50 w-50' style={{ paddingLeft: "30%" }}>
                        <img className="img qr" src={qrcode} alt='QR Code' />
                    </div>
                </div>
                <div className='mt-3 mx-5 px-5 d-flex flex-column justify-content-center align-items-center'>
                    <h4 className='fw-bold text-decoration-underline s1'>CERTIFICAT DE NATIONALITE CAMEROUNAISE</h4>
                </div>
                <div className='container mx-auto ps-5 mt-5 row'>
                    <div className='col-12'>
                        <div className='d-flex '>
                            <p className='s1'>
                                Nous soussigne. Président de tribunal de Première Instance de
                            </p>
                            <input type='text' className='monInpute2 form-control ms-3' />
                        </div>
                    </div>
                    <div className='col-12'>
                        <p className='s1'>
                            Vu les Artcles 6 (al a) et 42 de la loi no68/LF / 3 du 11 juin 1968 portant code de Nation Camerounaise :
                        </p>
                    </div>
                    <div className='col-12'>
                        <div className='d-flex '>
                            <p className='s1'>
                                Vu la requête en date du
                            </p>
                            <input type='text' className='monInpute2 form-control ms-3' />
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='d-flex '>
                            <p className='s1'>
                                De M
                            </p>
                            <input type='text' className='monInpute2 form-control ms-3' />
                        </div>
                    </div>
                    <div className='col-12'>
                        <p className='s1'>
                            L’enfant a l’obtention d’un Certificat de Nationalité Camerounaise :
                        </p>
                    </div>
                    <div className='col-12'>
                        <div className='d-flex '>
                            <p className='s1'>
                                Vu
                            </p>
                            <input type='text' className='monInpute3 form-control ms-3' />
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='mt-3 mx-5 px-5 d-flex flex-column justify-content-center align-items-center'>
                            <h4 className='fw-bold text-decoration-underline s1'>CERTIFIONS</h4>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='d-flex '>
                            <p className='s1'>
                                Que
                            </p>
                            <input type='text' className='monInpute2 form-control ms-3' />
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='d-flex '>
                            <p className='s1'>
                                Né (e) le
                            </p>
                            <input type='text' className='monInpute2 form-control ms-3' />
                        </div>
                    </div>

                    <div className='col-12'>
                        <div className='d-flex '>
                            <p className='s1'>
                                Arrondissement de
                            </p>
                            <input type='text' className='monInpute2 form-control ms-3' />
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='d-flex '>
                            <p className='s1'>
                                Département de
                            </p>
                            <input type='text' className='monInpute2 form-control ms-3' />
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='d-flex '>
                            <p className='s1'>
                                Fils ou Fille de
                            </p>
                            <input type='text' className='monInpute2 form-control ms-3' />
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='d-flex '>
                            <p className='s1'>
                                Et de
                            </p>
                            <input type='text' className='monInpute2 form-control ms-3' />
                        </div>
                    </div>
                    <div className='col-12'>
                        <p className='s1'>
                            Est Camerounais à titre de nationalité d'origine.
                        </p>
                    </div>
                    <div className='col-12'>
                        <p className='s1'>
                            En foi de quoi le Certificat de Natioonalité lui est délivré pour servir et valoir ce que de droit.
                        </p>
                    </div>
                    <div className='col-12 mt-5 mb-5 d-flex justify-content-end align-content-center align-items-center'>
                        <div className='pb-5 mb-5 me-5 pe-5'>
                            <div className='d-flex '>
                                <h6 className='s1 display-2'>
                                    FAIT EN NOTRE CABINET AU PALAIS DE JUSTICE DE
                                </h6>
                                <input type='text' className='monInput2 form-control ms-3' />
                            </div>

                            <div className='d-flex '>
                                <h6 className='s1 display-2'>LE
                                </h6>
                                <input type='text' className='monInput2 form-control ms-3' />
                            </div>
                            <h6 className='s1'>
                                LE PRESIDENT DU TRIBUNAL
                            </h6>
                        </div>
                    </div>

                </div>
            </div>

            <div className='col-12 mt-5 mb-5 d-flex justify-content-around align-content-center align-items-center'>
                <Link onClick={() => {
                    setcurrentRoute("demandesrecu")
                }}
                    className="text-decoration-none"
                    to={'/demandesrecu'}>
                    <button className='btn btn-outline-success fs-6 fw-bold px-5' ><FontAwesomeIcon className='annuler1' icon={faRectangleXmark} /><p className='annuler2'>Annuler</p></button>
                </Link>
                <Link onClick={() => {
                    setcurrentRoute("demandesrecu")
                }}
                    className="text-decoration-none"
                    to={'/demandesrecu'}>
                    <button className='btn btn-outline-success fs-6 fw-bold px-5' ><FontAwesomeIcon className='valider1' icon={faPenToSquare} /><p className='valider2'>Signer</p></button>
                </Link>
            </div>
        </div>
    )
}

export default DocumentsRecu
