import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function Retour2({ setcurrentRoute }) {
    const navigate = useNavigate()
    return (
        <>
            <button className='btn' onClick={() => {
                navigate(-1)
            }}>

                <div className="fs-1" style={{ color: "#496fcf" }} >
                    <FontAwesomeIcon icon={faCircleArrowLeft} />
                </div>
            </button>
        </>
    )
}

export default Retour2
