import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import "../css/tableauBord.css";
import Langue from '../sousComposants/langue';
import Retour from '../sousComposants/retour';

function TableauBord({ setcurrentRoute }) {
    const items = [];

    for (let i = 0; i < 100; i++) {
        items.push(<div className='line-file border border-1 rounded rounded-3 my-1 mx-3 p-2 h-25 w-95 d-flex justify-content-between'>
            <div className='s1'>Acte de naissance : LAGALISATION4958574348B</div>
            <div className='d-flex justify-content-around'>
                <div className='mx-2 s2'>{new Date().toLocaleDateString()}{new Date().toLocaleTimeString()}</div>
                <div className='s2' style={{ color: "#0c235f" }}><FontAwesomeIcon icon={faFileLines} /></div>
            </div>
        </div>);
    }

    return (
        <div className='mt-5 pt-5 ms-lg-5 d-flex flex-column position-relative'>
            <div className="container m-auto row">
                <Retour setcurrentRoute={setcurrentRoute} />
                <Langue />
            </div>
            <div className='col-lg-6 mx-auto mt-4'>
                {/* <FontAwesomeIcon icon={faMagnifyingGlass} /> */}
                <input
                    className="form-control"
                    type="text"
                    name="phoneNumber"
                    placeholder="Rechercher un document"
                >

                </input>
            </div>
            <div className='col-9 mx-auto mt-5 border border-2 rounded rounded-3 pt-3 mb-5 d-flex flex-column align-items-stretch divhaute'>
                {items}
            </div>
        </div>
    )
}

export default TableauBord