import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Contact from './composants/contact';
import DemandeRefus from "./composants/DemandeRefus";
import DemandesRecu from "./composants/demandesRecu";
import DemandesValide from "./composants/demandesValide";
import Document from './composants/document';
import Login from './composants/login';
import NumLegalisation from './composants/numLegalisation';
import Profil from "./composants/profil";
import TableauBord from './composants/tableauBord';
import DocumentsRecu from './sousComposants/documentsRecu';
import DocumentsRefus from './sousComposants/documentsRefus';
import DocumentsValide from './sousComposants/documentValide';
import Navbarre from './sousComposants/navbarre';
import NavbarreLogin from "./sousComposants/navbarreLogin";


function App() {

  const [currentRoute, setcurrentRoute] = useState();
  const location = useLocation();

  useEffect(() => {
    const path = window.location.pathname.toLocaleLowerCase();
    //console.log(path.slice(1, path.length));
    if (path.slice(1, path.length) === 'documentsrefus') {
      setcurrentRoute('demandesrefus');
    }
    else if (path.slice(1, path.length) === 'documentspartage') {
      setcurrentRoute('demandespartage');
    }
    else if (path.slice(1, path.length) === 'documentsrecu') {
      setcurrentRoute('demandesrecu');
    }
    else if (path.slice(1, path.length) === 'documentsvalide') {
      setcurrentRoute('demandesvalide');
    }
    else {
      setcurrentRoute(path.slice(1, path.length));
    }
  }, [location]);


  return (
    <>
      {
        currentRoute === "" || currentRoute === "contact" ? <NavbarreLogin setcurrentRoute={setcurrentRoute} currentRoute={currentRoute} /> : <Navbarre setcurrentRoute={setcurrentRoute} currentRoute={currentRoute} />
      }
      <Routes>
        <Route path="/" element={<Login setcurrentRoute={setcurrentRoute} />} />
        <Route path="/contact" element={<Contact setcurrentRoute={setcurrentRoute} />} />
        <Route path="/tableaubord" element={<TableauBord setcurrentRoute={setcurrentRoute} />} />
        <Route path="/numlegalisation" element={<NumLegalisation setcurrentRoute={setcurrentRoute} />} />
        <Route path="/document" element={<Document setcurrentRoute={setcurrentRoute} />} />
        <Route path="/demandesrefus" element={<DemandeRefus setcurrentRoute={setcurrentRoute} />} />
        <Route path="/demandesrecu" element={<DemandesRecu setcurrentRoute={setcurrentRoute} />} />
        <Route path="/demandesvalide" element={<DemandesValide setcurrentRoute={setcurrentRoute} />} />
        <Route path="/documentsrefus" element={<DocumentsRefus setcurrentRoute={setcurrentRoute} />} />
        <Route path="/documentsrecu" element={<DocumentsRecu setcurrentRoute={setcurrentRoute} />} />
        <Route path="/documentsvalide" element={<DocumentsValide setcurrentRoute={setcurrentRoute} />} />
        <Route path="/profil" element={<Profil setcurrentRoute={setcurrentRoute} />} />
      </Routes>
    </>
  );
}

export default function WrappedApp() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
