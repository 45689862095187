import React from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'

function Langue() {
    return (
        <div className="mt-4">
            <DropdownButton id="dropdown-basic-button" title="FR">
                <Dropdown.Item href="#/action-1">FR</Dropdown.Item>
                <Dropdown.Item href="#/action-2">EN</Dropdown.Item>
            </DropdownButton>
        </div>
    )
}

export default Langue