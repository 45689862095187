import React from 'react'
import { Link } from 'react-router-dom'
import '../css/document.css'
import logo from '../images/BUNEC.png'
import qrcode from '../images/qr-code.png'

function Document({ setcurrentRoute }) {
    return (
        <div style={{ marginTop: "100px" }}>
            <div className='col-9 mx-auto border border-2 pt-5 mb-5 d-flex flex-column align-items-stretch divhaute'>
                <div className='d-flex flex-wrap justify-content-around'>
                    <div className='d-flex flex-column align-items-center mb-3'>
                        <h1 className='fw-bolder fs-5 text-center s1'>REPUBLIQUE DE CAMEROUN</h1>
                        <h6 className='text-center s2'>Paix - Travail - Patrie</h6>
                        <div className='bar1'></div>
                        <h1 className='fw-medium fs-6 text-center mt-1 s1'>BUREAU NATIONNAL DE L'ETAT CIVIL</h1>
                        <div className='bar11'></div>
                    </div>
                    <div className='mb-3'>
                        <img className="img bunec" src={logo} alt='Logo' />
                    </div>
                    <div className='d-flex flex-column align-items-center mb-3'>
                        <h1 className='fw-bolder fs-5 text-center s1'>REPUBLIC OF CAMEROON</h1>
                        <h6 className='text-center s2'>Peace - Work - Fatherland</h6>
                        <div className='bar1'></div>
                        <h1 className='fw-medium fs-6 text-center mt-1 s1'>NATIONAL CIVIL DISTUS REGISTRATION OFFICE </h1>
                        <div className='bar11'></div>
                    </div>
                </div>
                <div className=' mt-1 row'>
                    <div className='col-4'>
                        <p className='fw-bold text-center align-self-center s2'>LAGALISATION4958574348B</p>
                    </div>
                    <div className='col-2'></div>
                    <div className='col-4 h-50 w-50' style={{ paddingLeft: "30%" }}>
                        <img className="img qr" src={qrcode} alt='QR Code' />
                    </div>
                </div>
                <div className='mt-3 mx-5 px-5 d-flex flex-column justify-content-center align-items-center'>
                    <h4 className='fw-bold text-decoration-underline s1'>COPIE CERTIFIER CONFORME D’ACTE DE NAISSANCE</h4>
                    <h4 className='fw-bold text-decoration-underline s1'>CERTIFIED COPY OF BIRTH CERTIFICATE</h4>
                </div>
                <div className='container mx-auto ps-5 mt-5 row'>
                    <div className='col-6'>
                        <p className='s1'>
                            Numéro de l'acte de naissance :
                            <br />
                            Birth certificcate number
                        </p>
                    </div>
                    <div className='col-6'>
                        <p className='s1'>
                            Numéro de l'acte de naissance :
                            <br />
                            Birth certificcate number
                        </p>
                    </div>
                    <div className='ms-2 col-12' style={{ backgroundColor: "#0c235f", height: "1px", width: "95%" }}></div>
                    {/* INFORMATIONS PERSONNELLES ...................................................................................*/}
                    <div className='col-12'>
                        <div className='mt-2'>
                            <h1 className='fs-6 s11'>
                                INFORMATIONS PERSONNELLES
                            </h1>
                            <h1 className='fs-6 s11'>
                                PERSONAL INFORMATION
                            </h1>
                        </div>
                    </div>
                    <div className='col-6'>
                        <p className='s1'>
                            Nom :
                            <br />
                            Surname
                        </p>
                    </div>
                    <div className='col-6'>

                        <p className='s1'>
                            Prénom(s) :
                            <br />
                            First & Midle Name(s)
                        </p>
                    </div>

                    <div className='col-6'>
                        <p className='s1'>
                            Date de naissance :
                            <br />
                            Date of birth
                        </p>
                    </div>
                    <div className='col-6'>
                        <p className='s1'>
                            Lieu de naissance :
                            <br />
                            Place of birth
                        </p>
                    </div>
                    <div className='col-6'>
                        <p className='s1'>
                            Sexe :
                            <br />
                            Sex
                        </p>
                    </div>
                    <div className='col-6'>
                        <p className='s1'>
                            Pays de naissance :
                            <br />
                            Country of birth
                        </p>
                    </div>
                    <div className='col-6'>
                        <p className='s1'>
                            Région de naissance :
                            <br />
                            Region of birth
                        </p>
                    </div>
                    <div className='col-6'>
                        <p className='s1'>
                            Département de naissance :
                            <br />
                            Department of birth
                        </p>
                    </div>
                    <div className='col-6'>
                        <p className='s1'>
                            Arrondissement de naissance :
                            <br />
                            Borough of birth
                        </p>
                    </div>
                    <div className='ms-2 col-12' style={{ backgroundColor: "#0c235f", height: "1px", width: "95%" }}></div>
                    {/* INFORMATIONS DES PARENTS ...................................................................................*/}
                    <div className='col-12'>
                        <div className='mt-2'>
                            <h1 className='fs-6 s11'>
                                INFORMATIONS DES PARENTS
                            </h1>
                            <h1 className='fs-6 s11'>
                                PARENTS' INFORMATION
                            </h1>
                        </div>
                    </div>
                    <div className='col-6'>
                        <p className='s1'>
                            Nom du père :
                            <br />
                            Name of the father
                        </p>
                    </div>
                    <div className='col-6'>
                        <p className='s1'>
                            Prénom(s) :
                            <br />
                            First & Midle Name(s)
                        </p>
                    </div>
                    <div className='col-6'>
                        <p className='s1'>
                            Date de naissance :
                            <br />
                            Date of birth
                        </p>
                    </div>
                    <div className='col-6'>
                        <p className='s1'>
                            Lieu de naissance :
                            <br />
                            Place of birth
                        </p>
                    </div>
                    <div className='col-12'>
                        <p className='s1'>
                            Proféssion du père :
                            <br />
                            Occupation of father
                        </p>
                    </div>
                    <div className='col-6'>
                        <p className='s1'>
                            Nom de la mère :
                            <br />
                            Name of the mother
                        </p>
                    </div>
                    <div className='col-6'>
                        <p className='s1'>
                            Prénom(s) :
                            <br />
                            First & Midle Name(s)
                        </p>
                    </div>
                    <div className='col-6'>
                        <p className='s1'>
                            Date de naissance :
                            <br />
                            Date of birth
                        </p>
                    </div>
                    <div className='col-6'>
                        <p className='s1'>
                            Lieu de naissance :
                            <br />
                            Place of birth
                        </p>
                    </div>
                    <div className='col-12'>
                        <p className='s1'>
                            Proféssion de la mère :
                            <br />
                            Occupation of mother
                        </p>
                    </div>
                    <div className='ms-2 col-12' style={{ backgroundColor: "#0c235f", height: "1px", width: "95%" }}></div>
                    {/* CENTRE D’ETAT CIVIL ...................................................................................*/}
                    <div className='col-12'>
                        <div className='mt-2'>
                            <h1 className='fs-6 s11'>
                                CENTRE D’ETAT CIVIL
                            </h1>
                            <h1 className='fs-6 s11'>
                                CIVIL STATUS CENTER
                            </h1>
                        </div>
                    </div>
                    <div className='col-12'>
                        <p className='s1'>
                            Numéro de la déclaration de naissance :
                            <br />
                            Birth declaration number
                        </p>
                    </div>
                    <div className='col-12'>
                        <p className='s1'>
                            Sur la déclaration de :
                            <br />
                            Birth declaration of
                        </p>
                    </div>
                    <div className='col-12'>
                        <p className='s1'>
                            Dréssé le :
                            <br />
                            Dressed the
                        </p>
                    </div>
                    <div className='col-6'>
                        <p className='s1'>
                            Officier d'état civil :
                            <br />
                            Officer of civil status
                        </p>
                    </div>
                    <div className='col-6'>
                        <p className='s1'>
                            Sécretaire d'état civil :
                            <br />
                            Secretary of civil status
                        </p>
                    </div>
                    <div className='ms-2 col-12' style={{ backgroundColor: "#0c235f", height: "1px", width: "95%" }}></div>
                    <div className='col-12 mt-2 d-flex justify-content-around align-content-center align-items-center'>
                        <div className=''>
                            <p className='s1'>
                                Fait le :
                                <br />
                                Done on
                            </p>
                        </div>
                        <div className=''>
                            <p className='s1'>
                                à :
                                <br />
                                at
                            </p>
                        </div>
                    </div>
                    <div className='col-12 mt-5 mb-5 d-flex justify-content-end align-content-center align-items-center'>
                        <div className='pb-5 mb-5 me-5 pe-5'>
                            <h6 className='s1'>
                                Directeur Général du BUNEC
                                <br />
                                General Director of the BUNEC
                            </h6>
                        </div>
                    </div>

                </div>
            </div>

            <div className='col-12 mt-5 mb-5 d-flex justify-content-center align-content-center align-items-center'>
                <Link onClick={() => {
                    setcurrentRoute("tableaubord")
                }}
                    className="text-decoration-none"
                    to={'/tableaubord'}>
                    <button className='btn btn-outline-danger fw-bold px-5' >Ecraser</button>
                </Link>
            </div>
        </div>
    )
}

export default Document